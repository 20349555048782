import React from 'react';
import { LINE_HEIGHT } from '.';
import { IAsset, IResizeDirection } from '../../../types';

import styles from './index.module.css';
import Selection from './Selection';

const Layer = ({
  asset,
  isSelected,
  onSelect,
  onResizeStart,
}: {
  asset: IAsset;
  isSelected: boolean;
  onSelect: React.MouseEventHandler;
  onResizeStart: (e: React.MouseEvent, direction: IResizeDirection) => void;
}) => {
  return (
    <div className={styles.layer} style={{ zIndex: asset.zIndex }}>
      <div
        className={styles.asset}
        style={{
          top: asset.y,
          left: asset.x,
          width: asset.width,
        }}
        onMouseDown={onSelect}
      >
        {asset.src ? (
          <img src={asset.src} alt={asset.src} data-name={asset.name} />
        ) : (
          <p
            style={{
              fontSize: asset.fontSize,
              lineHeight: LINE_HEIGHT,
              color: asset.color,
            }}
          >
            {asset.text}
          </p>
        )}
        {isSelected ? (
          <Selection
            onResizeStart={(e, direction) => {
              onResizeStart(e, direction);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Layer;
