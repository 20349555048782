import React from 'react';

import { Flex, Progress } from '@chakra-ui/react';

const AppLoader = () => {
  return (
    <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
      <Progress
        width={196}
        borderRadius={2}
        isIndeterminate
        height={4}
        isAnimated
      />
    </Flex>
  );
};

export default AppLoader;
