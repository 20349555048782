import React, { ChangeEvent } from 'react';

export const InputFiles = ({
  onChange,
}: {
  onChange: (files: File[]) => void;
}) => {
  const handleUpload = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    const files = Array.from(target.files || []);
    onChange(files);
  };

  return (
    <label
      htmlFor="images"
      style={{
        display: 'block',
        height: 52,
        width: '100%',
        borderRadius: 16,
        padding: 16,
        backgroundColor: '#324367',
        color: 'white',
        fontWeight: 600,
        fontSize: 14,
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      Upload from Computer
      <input
        type="file"
        id="images"
        name="images"
        accept="image/png, image/jpeg, image/svg+xml"
        onChange={handleUpload}
        multiple
        style={{ display: 'none' }}
      />
    </label>
  );
};
