import React from 'react';
import { useEffect, useState } from 'react';
import { useKeyPress } from '../../../hooks/useKeyPress';
import {
  IAction,
  IAsset,
  IBackground,
  IElement,
  IResizeDirection,
} from '../../../types';
import { useAiFlow } from '../../../xstate/aiFlow';
import Layer from './Layer';

import styles from './index.module.css';
import useClickOutside from '../../../hooks/useClickOutside';

export const LINE_HEIGHT = 1.1;

const getCanvasDimensions = (canvasEl: HTMLElement | null) => {
  if (!canvasEl)
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };

  return {
    x: canvasEl.offsetLeft,
    y: canvasEl.offsetTop,
    width: canvasEl.offsetWidth,
    height: canvasEl.offsetHeight,
  };
};

const getAssetProps = (target: HTMLElement) => {
  const fontSize = parseInt(
    window.getComputedStyle(target, null).getPropertyValue('font-size'),
    10,
  );

  return {
    width: target.getBoundingClientRect().width,
    height:
      'src' in target
        ? target.getBoundingClientRect().height
        : fontSize * LINE_HEIGHT,
    src: 'src' in target ? (target as HTMLImageElement).src : undefined,
    name:
      'src' in target
        ? (target as HTMLImageElement).getAttribute('data-name') || ''
        : undefined,
    text:
      'src' in target ? undefined : (target as HTMLElement).textContent || '',
    color: window.getComputedStyle(target, null).getPropertyValue('color'),
    fontSize: fontSize,
  };
};

const Canvas = ({
  canvasRef,
  background,
  elements,
  setElements,
}: {
  canvasRef: React.MutableRefObject<HTMLDivElement | null>;
  background: IBackground | null;
  elements: IElement[];
  setElements: React.Dispatch<React.SetStateAction<IElement[]>>;
}) => {
  const aiFlow = useAiFlow();
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [selected, setSelected] = useState<IAsset | null>(null);
  const [action, setAction] = useState<IAction | null>(null);
  const [resizeDirection, setResizeDirection] =
    useState<IResizeDirection | null>(null);
  const [cursorPosition, setCursorPosition] = useState<[number, number]>([
    0, 0,
  ]);

  // When clicking outside of the canvas we want to deselect any of of the assets
  useClickOutside(canvasRef, () => {
    setSelected(null);
  });

  const deletePress = useKeyPress('Backspace');

  useEffect(() => {
    if (deletePress && selected) {
      setElements((els) => els.filter((el) => el.uid !== selected.uid));
      setAssets((els) => els.filter((el) => el.uid !== selected.uid));

      if (!selected.text && selected.name) {
        aiFlow.aifFlowService.send({
          type: 'DELETE_FILE_SELECT',
          name: selected.name,
        });
      }

      if (selected.text) {
        aiFlow.aifFlowService.send({
          type: 'DELETE_COPY',
        });
      }

      setSelected(null);
    }
  }, [deletePress, selected]);

  useEffect(() => {
    const canvas = getCanvasDimensions(canvasRef?.current);

    setAssets((prevAssets) => {
      const newAssets = elements
        .filter((el) => !prevAssets.map((a) => a.uid).includes(el.uid))
        .map((el, i) => {
          const { width, height, ...rest } = getAssetProps(el.el);
          const asset = {
            uid: el.uid,
            x: el.x ? el.x - canvas.x : canvas.width / 2 - width / 2,
            y: el.y ? el.y - canvas.y : canvas.height / 2 - height / 2,
            width: width,
            height: height,
            zIndex: Math.max(...prevAssets.map((a) => a.zIndex), 0) + i + 1,
            ...rest,
          };

          return asset;
        });

      // When new asset get add we want to select by default
      if (newAssets.length > 0) {
        setSelected(newAssets[newAssets.length - 1]);
      }

      return [...prevAssets, ...newAssets];
    });
  }, [elements]);

  const updateAssetsOrder = (nextSelected: IAsset) => {
    assets.forEach((asset) => {
      if (asset.zIndex < nextSelected.zIndex) {
        return;
      }

      asset.zIndex -= 1;
    });
    nextSelected.zIndex = assets.length;
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!selected) return;

    const diffX = e.pageX - cursorPosition[0];
    const diffY = e.pageY - cursorPosition[1];
    const respectRatio = !selected.text;

    if (action === IAction.MOVE) {
      selected.x += diffX;
      selected.y += diffY;
    }

    if (action === IAction.RESIZE && resizeDirection) {
      const ratio = selected.width / selected.height;

      if (resizeDirection === IResizeDirection.TOP) {
        selected.height += diffY * -1;
        selected.width = respectRatio
          ? selected.height * ratio
          : selected.width;
        selected.y += diffY * 1;
        selected.fontSize =
          selected.fontSize *
          (1 + ((diffY / LINE_HEIGHT) * -1) / selected.fontSize);
      }

      if (resizeDirection === IResizeDirection.RIGHT) {
        selected.width += diffX * 1;
        selected.height = respectRatio
          ? selected.width / ratio
          : selected.height;
      }

      if (resizeDirection === IResizeDirection.BOTTOM) {
        selected.height += diffY * 1;
        selected.width = respectRatio
          ? selected.height * ratio
          : selected.width;
        selected.fontSize =
          selected.fontSize *
          (1 + ((diffY / LINE_HEIGHT) * 1) / selected.fontSize);
      }

      if (resizeDirection === IResizeDirection.LEFT) {
        selected.width += diffX * -1;
        selected.height = respectRatio
          ? selected.width / ratio
          : selected.height;
        selected.x += diffX * 1;
      }

      if (resizeDirection === IResizeDirection.TOP_LEFT) {
        const prevHeight = selected.height;

        if (respectRatio) {
          selected.width += diffX * -1;
          selected.height = selected.width / ratio;
          selected.x += diffX * 1;
          selected.y += (prevHeight - selected.height) * 1;
        } else {
          selected.width += diffX * -1;
          selected.height += diffY * -1;
          selected.x += diffX * 1;
          selected.y += diffY * 1;
        }

        selected.fontSize =
          selected.fontSize *
          (1 + ((diffY / LINE_HEIGHT) * -1) / selected.fontSize);
      }

      if (resizeDirection === IResizeDirection.TOP_RIGHT) {
        const prevHeight = selected.height;

        if (respectRatio) {
          selected.width += diffX * 1;
          selected.height = respectRatio
            ? selected.width / ratio
            : selected.height;

          selected.y += (prevHeight - selected.height) * 1;
        } else {
          selected.width += diffX * 1;
          selected.height += diffY * -1;
          selected.y += diffY * 1;
        }

        selected.fontSize =
          selected.fontSize *
          (1 + ((diffY / LINE_HEIGHT) * -1) / selected.fontSize);
      }

      if (resizeDirection === IResizeDirection.BOTTOM_RIGHT) {
        if (respectRatio) {
          selected.width += diffX * 1;
          selected.height = selected.width / ratio;
        } else {
          selected.width += diffX * 1;
          selected.height += diffY * 1;
        }

        selected.fontSize =
          selected.fontSize *
          (1 + ((diffY / LINE_HEIGHT) * 1) / selected.fontSize);
      }

      if (resizeDirection === IResizeDirection.BOTTOM_LEFT) {
        if (respectRatio) {
          selected.width += diffX * -1;
          selected.height = selected.width / ratio;
          selected.x += diffX * 1;
        } else {
          selected.width += diffX * -1;
          selected.height += diffY * 1;
          selected.x += diffX * 1;
        }

        selected.fontSize =
          selected.fontSize *
          (1 + ((diffY / LINE_HEIGHT) * 1) / selected.fontSize);
      }
    }

    setCursorPosition([e.pageX, e.pageY]);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    setAction(null);
    setResizeDirection(null);
    setCursorPosition([0, 0]);
  };

  const handleSelect = (e: React.MouseEvent, asset: IAsset) => {
    setSelected(asset);
    setAction(IAction.MOVE);
    setCursorPosition([e.pageX, e.pageY]);
    updateAssetsOrder(asset);
  };

  const handleResizeMouseDown = (
    e: React.MouseEvent,
    asset: IAsset,
    direction: IResizeDirection,
  ) => {
    e.stopPropagation();

    setSelected(asset);
    setAction(IAction.RESIZE);
    setResizeDirection(direction);
    setCursorPosition([e.pageX, e.pageY]);
    updateAssetsOrder(asset);
  };

  return (
    <div onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
      <div className={styles.canvas} ref={canvasRef} data-role="canvas">
        {background ? (
          <img
            src={background.url}
            alt="background"
            className={styles.background}
          />
        ) : null}
        {assets.map((asset) => (
          <Layer
            key={asset.uid}
            asset={asset}
            isSelected={selected?.uid === asset.uid}
            onSelect={(e) => handleSelect(e, asset)}
            onResizeStart={(e, direction) =>
              handleResizeMouseDown(e, asset, direction)
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Canvas;
