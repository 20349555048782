import {
  Flex,
  Button as CButton,
  Text,
  Box,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import SvgArrowRight from '../svg/SvgArrowRight';

const TextForm = ({
  text,
  color,
  onTextChange,
  onColorChange,
  onTextClick,
  asSelectedText,
}: {
  text: string;
  color: string;
  onTextChange: (text: string) => void;
  onColorChange: (color: string) => void;
  onTextClick: (el: HTMLElement) => void;
  asSelectedText: boolean;
}) => {
  const textRef = useRef<HTMLParagraphElement | null>(null);

  return (
    <div>
      {/* <input
        type="text"
        name="text"
        value={text}
        placeholder="Add copy"
        onChange={(e) => onTextChange(e.target.value)}
      /> */}
      <Box position="relative">
        <Input
          name="text"
          value={text}
          onChange={(e) => onTextChange(e.target.value)}
          _placeholder={{
            color: 'lightGrey',
          }}
          lineHeight="22px"
          height={60}
          px={16}
          borderRadius={16}
          focusBorderColor="purple"
          placeholder="Type text"
        />

        <Popover placement="top-end" offset={[12, 12]}>
          <PopoverTrigger>
            <CButton
              position="absolute"
              zIndex={9999}
              border="1px solid rgba(144, 159, 191, 0.2)"
              right={18}
              top={22}
              overflow="hidden"
              p={0}
              height={16}
              width={16}
              borderRadius={4}
              bg="transparent"
              _hover={{ bg: 'transparent' }}
            >
              <Box bg={color} height="100%" width="100%" />
            </CButton>
          </PopoverTrigger>

          <PopoverContent>
            <Box position="absolute">
              <HexColorPicker color={color} onChange={onColorChange} />
            </Box>
          </PopoverContent>
        </Popover>
      </Box>

      {/* <Drag
        onDragStart={(dragged) => setDragged(dragged)}
        onDragEnd={() => setDragged(null)}
        onClick={() => onTextClick(textRef.current!)}
      >
        <p children={text} style={{ color }} />
      </Drag> */}
      <p
        ref={textRef}
        style={{
          color,
          visibility: 'hidden',
          position: 'absolute',
          margin: 0,
          width: '300px',
        }}
      >
        {text}
      </p>

      <Box height={1} my={20} width="100%" bg="#8084941A" />

      {!asSelectedText && (
        <CButton
          _hover={{ bg: 'transparent' }}
          bg="transparent"
          _focus={{ bg: 'transparent' }}
          onClick={() => onTextClick(textRef.current!)}
        >
          <Flex alignItems="center">
            <SvgArrowRight color="lightGrey" mr={8} />
            <Text fontWeight={400} lineHeight={1.5} color="lightGrey">
              Add text to canvas
            </Text>
          </Flex>
        </CButton>
      )}
    </div>
  );
};

export default TextForm;
