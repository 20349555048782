import { Magic } from 'magic-sdk';

import { login as apiLogin } from '../../utils/api';

export const magic = new Magic(process.env.REACT_APP_MAGIC_API_KEY as string);

export const logout = () => magic.user.logout();

// same tab login flow
export const login = async (email: string) => {
  const didToken = await magic.auth.loginWithMagicLink({
    email,
    showUI: false,
    redirectURI: new URL('/callback', window.location.origin).href, // optional redirect back to your app after magic link is clicked
  });

  if (!didToken) return null;

  try {
    await apiLogin(didToken);
    return await magic.user.getMetadata();
  } catch (err) {
    return null;
  }
};

// new tab login flow (magic redirect)
export const callbackLogin = async () => {
  await magic.auth.loginWithCredential();
  return await magic.user.getMetadata();
};

export const init = async () => {
  const isLoggedIn = await magic.user.isLoggedIn();

  if (isLoggedIn) {
    /**
     * todo: do we need to request backend /login at this point?
     * case: submit email, close tab, proceed with redirect url tab - backend /login is not requested, user is logged in to UI
     */
    return await magic.user.getMetadata();
  }

  return null;
};
