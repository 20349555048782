import { MagicUserMetadata } from 'magic-sdk';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import AppLoader from '../common/AppLoader';
import * as magic from './magic';

interface IAuthContextType {
  user: MagicUserMetadata | undefined;
  isLoading: boolean;
  isFetching: boolean;
  isLoggedIn: boolean;

  login: (token: string) => Promise<MagicUserMetadata | null>;
  logout: () => void;
}

const initialState = {
  user: undefined,
  isLoading: false,
  isFetching: false,
  isLoggedIn: false,
  logout: magic.logout,
  login: magic.login,
};

export const AuthContext = createContext<IAuthContextType>(initialState);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [user, setUser] = useState<MagicUserMetadata>();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true); // app loading state
  const [isFetching, setFetching] = useState(false); // user auth updating state

  const logout = async () => {
    setFetching(true);
    await magic.logout();
    setFetching(false);
    setUser(undefined);
    setLoggedIn(false);
  };

  const login = async (email: string) => {
    setFetching(true);
    const userData = await magic.login(email);
    setFetching(false);

    if (userData) {
      setUser(userData);
      setLoggedIn(true);
    }

    return userData;
  };

  useEffect(() => {
    if (location.pathname === '/callback') {
      magic.callbackLogin().then((userData) => {
        setLoading(false);
        setLoggedIn(!!userData);
        setUser(userData);
      });
      return;
    }

    magic.init().then((userData) => {
      setLoading(false);
      setLoggedIn(!!userData);
      setUser(userData || undefined);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: user,
        isLoading,
        isFetching,
        isLoggedIn,
        logout,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useContext(AuthContext);
  const location = useLocation();

  if (auth.isLoading) {
    return <AppLoader />;
  }

  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export const RequireSignOut = ({ children }: { children: JSX.Element }) => {
  const auth = useContext(AuthContext);
  const location = useLocation();

  if (auth.isLoading) {
    return <AppLoader />;
  }

  if (auth.user) {
    return <Navigate to="/app" state={{ from: location }} replace />;
  }

  return children;
};
