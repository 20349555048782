import './animations.css';

import cn from 'classnames';
import React, { ChangeEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../auth/AuthProvider';
import Button from './Button';
import styles from './signUpForm.module.css';

const isValidEmail = (value: string) => {
  return /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(value);
};

const SignUpForm = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [invalid, setInvalid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const config = {
    placeholder: 'example@email.com',
    action: 'Sign Up',
    success:
      'An email with a login link will be sent to your email address shortly',
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setInvalid(false);
  };

  const handleSubmit = () => {
    if (email) {
      const isValid = isValidEmail(email);

      if (isValid) {
        setSubmitted(true);
        auth.login(email);
      } else {
        setInvalid(true);
      }
    }
  };

  if (auth.isLoggedIn) {
    return (
      <div className={styles.root}>
        <Button text="Go to app" onClick={() => navigate('/app')} />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div
        className={cn(styles.container, styles.form)}
        data-invalid={email && invalid}
        data-animation={email && submitted ? 'slideOutJump' : 'stretch'}
        style={{
          opacity: email && submitted ? 0 : 1,
        }}
      >
        <input
          type="email"
          className={styles.input}
          placeholder={config.placeholder}
          onChange={handleChange}
        />
        <Button text={config.action} onClick={handleSubmit} />
      </div>
      <div
        className={cn(styles.container, styles.success)}
        data-animation={email && submitted ? 'slideInJump' : ''}
        style={{
          animationPlayState: email && submitted ? 'running' : 'paused',
          opacity: email && submitted ? 1 : 0,
        }}
      >
        {config.success}
      </div>
    </div>
  );
};

export default SignUpForm;
