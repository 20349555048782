import React from 'react';

const Drop = ({
  children,
  onDrop,
}: {
  children: React.ReactNode;
  onDrop: React.DragEventHandler;
}) => {
  const handleDragOver = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  return (
    <div onDragOver={handleDragOver} onDrop={onDrop}>
      {children}
    </div>
  );
};

export default Drop;
