import { Box, Flex, Image, Progress, SimpleGrid, Text } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import SvgPicture from '../common/svg/SvgPicture';
import Lottie from 'lottie-react';
import animationJson from '../common/lotties/loading.json';
import Canvas from '../common/canvas';
import Drop from '../common/drag&drop/Drop';
import { IDrag, IElement } from '../../types';
import styles from './backgroundImg.module.css';
import {
  selectBackgroundsPreposition,
  selectCurrentStep,
  selectEnhancements,
  selectFullPrompt,
  selectIsGeneratingLoadingState,
  selectIsIdleState,
  selectIsSelectEnhancementsState,
  selectSearchingTerm,
  selectSelectedBackground,
  useAiFlow,
} from '../../xstate/aiFlow';
import { useSelector } from '@xstate/react';

const IdleSection = ({ enhanceOn }: { enhanceOn?: boolean }) => {
  const aiFlow = useAiFlow();
  const term = useSelector(aiFlow.aifFlowService, selectSearchingTerm);
  const enhancements = useSelector(aiFlow.aifFlowService, selectEnhancements);
  const isIdleState = useSelector(aiFlow.aifFlowService, selectIsIdleState);
  const isSelectEnhancementsState = useSelector(
    aiFlow.aifFlowService,
    selectIsSelectEnhancementsState,
  );

  const text =
    term.length > 0
      ? term
      : 'A summary of your ad background including any selected enhancements will be displayed here.';

  if (!isIdleState && !isSelectEnhancementsState) {
    return null;
  }

  return (
    <motion.div
      key="idle"
      initial={{ y: -10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ y: 10, duration: 0.2 }}
    >
      <Text
        color="darkGrey"
        fontWeight={600}
        fontSize={24}
        lineHeight="33.24px"
        textAlign="center"
      >
        {enhanceOn
          ? 'Enhance Your Background Image'
          : 'Build Your Ad Background'}
      </Text>

      <AnimatePresence>
        {enhanceOn && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box px={{ base: '10%', xl: '20%' }}>
              <Text
                mt={8}
                textAlign="center"
                fontSize={14}
                lineHeight="20px"
                color="lightGrey"
              >
                Additional details can be provided about the specific background
                image that you have in mind.
              </Text>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>

      <Box mt={47}>
        {enhancements.length > 0 ? (
          <Flex alignItems="center" justifyContent="center">
            <SimpleGrid
              columns={enhancements.length < 3 ? enhancements.length : 3}
              spacing={enhancements.length < 3 ? 24 : 16}
            >
              <AnimatePresence>
                {enhancements.map((e) => {
                  return (
                    <motion.div
                      key={e.enhancement}
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Box
                        height={153}
                        width={153}
                        borderRadius={48}
                        overflow="hidden"
                      >
                        <img src={e.preset.image} height="100%" width="100%" />
                      </Box>
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </SimpleGrid>
          </Flex>
        ) : (
          <Flex alignItems="center" justifyContent="center">
            <Flex
              height={153}
              width={153}
              borderRadius={48}
              bg="#F6F8FA"
              alignItems="center"
              justifyContent="center"
            >
              <SvgPicture color="lightGrey" />
            </Flex>
          </Flex>
        )}
      </Box>

      {/* we don't want to flick when text change from 2 line to 1 */}
      <Box minHeight={80} px={{ base: '10%', xl: '20%' }}>
        <Text
          mt={24}
          color="ligthGrey"
          fontWeight={400}
          lineHeight="22px"
          textAlign="center"
        >
          {text}
        </Text>
      </Box>
    </motion.div>
  );
};

const CompletedSection = () => {
  const aiFlow = useAiFlow();
  const selectedBackground = useSelector(
    aiFlow.aifFlowService,
    selectSelectedBackground,
  );
  const backgroundsPreposition = useSelector(
    aiFlow.aifFlowService,
    selectBackgroundsPreposition,
  );

  if (!selectedBackground) {
    return null;
  }
  return (
    <motion.div
      key="completed"
      initial={{ y: -10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ y: 10, duration: 0.2 }}
    >
      <Flex alignItems="center" justifyContent="center" flexDirection="column">
        <Box
          height={221}
          width={221}
          borderRadius={48}
          bg="#F6F8FA"
          overflow="hidden"
        >
          {selectedBackground && (
            <Image src={selectedBackground} height="100%" width="100%" />
          )}
        </Box>

        <Text
          mt={16}
          fontWeight={600}
          fontSize={24}
          lineHeight="33px"
          color="darkGrey"
        >
          Currently Selected
        </Text>

        <SimpleGrid columns={4} spacing={16} mt={54} px={20}>
          {backgroundsPreposition.map((el) => (
            <Image
              onClick={() =>
                aiFlow.aifFlowService.send({
                  type: 'SELECT_BACKGROUND',
                  img: el,
                })
              }
              cursor="pointer"
              key={el}
              borderRadius={48}
              src={el}
              height="100%"
              width="100%"
            />
          ))}
        </SimpleGrid>
      </Flex>
    </motion.div>
  );
};

const GeneratingSection = () => {
  const aiFlow = useAiFlow();
  const fullPrompt = useSelector(aiFlow.aifFlowService, selectFullPrompt);
  const isLoadingState = useSelector(
    aiFlow.aifFlowService,
    selectIsGeneratingLoadingState,
  );
  const enhancements = useSelector(aiFlow.aifFlowService, selectEnhancements);
  if (!isLoadingState) {
    return null;
  }
  return (
    <motion.div
      key="generating"
      initial={{ y: -10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ y: 10, duration: 0.2 }}
    >
      <Box px={{ base: '10%', xl: '20%' }}>
        <Flex alignItems="center" justifyContent="center">
          <Lottie animationData={animationJson} loop />
        </Flex>

        <Flex alignItems="center" justifyContent="center">
          <Progress
            width={196}
            borderRadius={2}
            isIndeterminate
            height={4}
            isAnimated
          />
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          mt={48}
        >
          <SimpleGrid
            columns={enhancements.length < 3 ? enhancements.length : 3}
            spacing={8}
          >
            {enhancements.map((e) => {
              return (
                <Box
                  key={e.enhancement}
                  height={48}
                  width={48}
                  borderRadius={16}
                  overflow="hidden"
                >
                  <img src={e.preset.image} height="100%" width="100%" />
                </Box>
              );
            })}
          </SimpleGrid>

          <Box mt={24}>
            <Text color="lightGrey" fontWeight={400}>
              {fullPrompt}
            </Text>
          </Box>
        </Flex>
      </Box>
    </motion.div>
  );
};

interface Props {
  enhanceOn?: boolean;
  elements: IElement[];
  setElements: React.Dispatch<React.SetStateAction<IElement[]>>;
  canvasRef: React.RefObject<HTMLDivElement>;
}

export const BackgroundImg = ({
  enhanceOn,
  elements,
  setElements,
  canvasRef,
}: Props) => {
  const aiFlow = useAiFlow();
  const [dragged, setDragged] = useState<IDrag | null>(null);
  const selectedBackground = useSelector(
    aiFlow.aifFlowService,
    selectSelectedBackground,
  );
  const currentStep = useSelector(aiFlow.aifFlowService, selectCurrentStep);

  const handleDrop = (e: React.DragEvent) => {
    if (!dragged) return;

    setElements((prevElements) => [
      ...prevElements,
      {
        el: dragged.el,
        uid: Math.random(),
        x: e.pageX - dragged.x,
        y: e.pageY - dragged.y,
      },
    ]);
  };

  if (currentStep !== 1) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <Drop onDrop={handleDrop}>
            <Canvas
              canvasRef={canvasRef}
              background={{ url: selectedBackground! }}
              elements={elements}
              setElements={setElements}
            />
          </Drop>
        </div>
      </div>
    );
  }

  return (
    <Flex
      minHeight={686}
      width="100%"
      border="1px solid rgba(144, 159, 191, 0.2)"
      borderRadius={24}
      bg="white"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={24}
    >
      <AnimatePresence>
        <IdleSection key="idlesection" enhanceOn={enhanceOn} />

        <GeneratingSection key="generatingSection" />

        <CompletedSection key="completedSection" />
      </AnimatePresence>
    </Flex>
  );
};
