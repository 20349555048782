import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export default function SvgArrowLeft(props: IconProps) {
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path
        d="M8.83334 10.589C8.67711 10.4327 8.58935 10.2208 8.58935 9.99985C8.58935 9.77888 8.67711 9.56696 8.83334 9.41069L12.6542 5.58902C12.806 5.43185 12.89 5.22135 12.8881 5.00285C12.8862 4.78435 12.7985 4.57534 12.644 4.42084C12.4895 4.26633 12.2805 4.17869 12.062 4.17679C11.8435 4.17489 11.633 4.25889 11.4758 4.41068L7.6575 8.23235C7.18883 8.70117 6.92554 9.33694 6.92554 9.99985C6.92554 10.6628 7.18883 11.2985 7.6575 11.7674L11.4792 15.589C11.6363 15.7408 11.8468 15.8248 12.0653 15.8229C12.2838 15.821 12.4928 15.7334 12.6474 15.5789C12.8019 15.4244 12.8895 15.2153 12.8914 14.9969C12.8933 14.7784 12.8093 14.5679 12.6575 14.4107L8.83334 10.589Z"
        fill="currentColor"
      />
    </Icon>
  );
}
