import React, { useEffect } from 'react';
import {
  AspectRatio,
  Box,
  Button as CButton,
  Flex,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import {
  AnimatePresence,
  motion,
  useSpring,
  useTransform,
} from 'framer-motion';
import SvgPlus from '../common/svg/SvgPlus';
import Button from '../common/Button';
import SvgReset from '../common/svg/SvgReset';
import { IEnhancement, IPreset } from '../../utils/api';
import { PROMPT_ENHANCEMENTS } from '../../constants/enhancements';
import { useSelector } from '@xstate/react';
import {
  selectCurrentStep,
  selectEnhancements,
  selectIsGeneratingState,
  selectIsGeneratingSuccessState,
  selectIsIdleState,
  selectIsPresetSelectionState,
  selectIsSelectEnhancementsState,
  selectSearchingTerm,
  selectSelectedCategory,
  useAiFlow,
} from '../../xstate/aiFlow';
import SvgArrowLeft from '../common/svg/SvgArrowLeft';

const Enhancement = ({
  enhancement,
  onSelect,
}: {
  enhancement: IEnhancement;
  onSelect: (enhancement: IEnhancement) => void;
}) => {
  return (
    <Box
      onClick={() => onSelect(enhancement)}
      cursor="pointer"
      borderRadius={8}
      overflow="hidden"
      position="relative"
      backdropFilter="blur(16px)"
    >
      <img src={enhancement.image} />

      <Flex
        alignItems="center"
        justifyContent="center"
        height={32}
        bottom={8}
        left={8}
        right={8}
        position="absolute"
        bg="#0F283099"
        borderRadius={12}
        backdropFilter="blur(2px)"
      >
        <Text
          color="white"
          fontSize={14}
          lineHeight="24px"
          fontWeight={400}
          textAlign="center"
        >
          {enhancement.label}
        </Text>
      </Flex>
    </Box>
  );
};

const Preset = ({
  preset,
  onSelect,
}: {
  preset: IPreset;
  onSelect: (preset: IPreset) => void;
}) => {
  return (
    <Box
      onClick={() => onSelect(preset)}
      cursor="pointer"
      borderRadius={8}
      overflow="hidden"
      position="relative"
      backdropFilter="blur(16px)"
    >
      <AspectRatio ratio={1} width="100%">
        <img src={preset.image} />
      </AspectRatio>

      <Flex
        alignItems="center"
        justifyContent="center"
        height={32}
        bottom={8}
        left={8}
        right={8}
        position="absolute"
        bg="#0F283099"
        borderRadius={12}
        backdropFilter="blur(2px)"
      >
        <Text
          color="white"
          fontSize={14}
          lineHeight="24px"
          fontWeight={400}
          textAlign="center"
        >
          {preset.label}
        </Text>
      </Flex>
    </Box>
  );
};

export const EnhancementsSelection = () => {
  const aiFlow = useAiFlow();
  const searchingTerm = useSelector(aiFlow.aifFlowService, selectSearchingTerm);
  const currentFlowStep = useSelector(aiFlow.aifFlowService, selectCurrentStep);
  const isIdleState = useSelector(aiFlow.aifFlowService, selectIsIdleState);
  const selectedCategory = useSelector(
    aiFlow.aifFlowService,
    selectSelectedCategory,
  );
  const isGeneratingState = useSelector(
    aiFlow.aifFlowService,
    selectIsGeneratingState,
  );
  const isSelectEnhancementsState = useSelector(
    aiFlow.aifFlowService,
    selectIsSelectEnhancementsState,
  );
  const isGeneratingSuccessState = useSelector(
    aiFlow.aifFlowService,
    selectIsGeneratingSuccessState,
  );
  const isPresetSelectionState = useSelector(
    aiFlow.aifFlowService,
    selectIsPresetSelectionState,
  );
  const enhancements = useSelector(aiFlow.aifFlowService, selectEnhancements);

  const x = useSpring(0, { duration: 200 });

  const enhanceTransform = useTransform(x, [0, 1], [100, 0]);
  const enhanceOpacity = useTransform(x, [0, 1], [0, 1]);

  const enhanceStep1Transform = useTransform(x, [0, 1], [0, -100]);
  const enhanceStep1Opacity = useTransform(x, [0, 1], [1, 0]);

  useEffect(() => {
    x.set(isSelectEnhancementsState ? 1 : 0);
  }, [isSelectEnhancementsState]);

  const hasEnhancement = enhancements.length > 0;

  return (
    <>
      <Box
        mt={enhancements.length > 0 ? 8 : 0}
        mb={enhancements.length > 0 ? 14 : 0}
      >
        <AnimatePresence>
          {enhancements.map((e) => (
            <motion.div
              key={e.enhancement}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              // exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <Flex alignItems="center" mt={12}>
                <Box h={20} mr={10} w={20} borderRadius={10} overflow="hidden">
                  <img src={e.preset.image} />
                </Box>
                <Text fontWeight={400} color="darkGrey" lineHeight={1.5}>
                  {e.preset.label}
                </Text>
              </Flex>
            </motion.div>
          ))}
        </AnimatePresence>
      </Box>

      {(isIdleState || isSelectEnhancementsState) && (
        <Box height={23}>
          <motion.div
            style={{
              x: enhanceTransform,
              opacity: enhanceOpacity,
              position: 'absolute',
            }}
          >
            <Flex alignItems="center">
              {selectedCategory && (
                <CButton
                  onClick={() => aiFlow.aifFlowService.send('BACK_CATEGORY')}
                  mr={18}
                  cursor="pointer"
                  bg="transparent"
                  height={20}
                  width={20}
                  _hover={{ bg: 'transparent' }}
                  _focus={{ bg: 'transparent' }}
                >
                  <SvgArrowLeft color="lightGrey" />
                </CButton>
              )}
              <Text
                color="darkGrey"
                fontWeight={400}
                lineHeight="22px"
                textTransform="uppercase"
              >
                {selectedCategory
                  ? `SELECT ${selectedCategory.title}`
                  : 'SELECT ENHANCEMENT'}
              </Text>
            </Flex>
          </motion.div>
          <motion.div
            style={{
              x: enhanceStep1Transform,
              opacity: enhanceStep1Opacity,
              position: 'absolute',
            }}
          >
            <CButton
              style={{
                display: isSelectEnhancementsState ? 'none' : 'flex',
                backgroundColor: 'transparent',
                height: 22,
              }}
              onClick={() => aiFlow.aifFlowService.send('SELECT_ENHANCEMENTS')}
              alignItems="center"
              disabled={searchingTerm.length === 0 || !isIdleState}
              pl={hasEnhancement ? 0 : 16}
              _disabled={{ opacity: 1, cursor: 'not-allowed' }}
            >
              <SvgPlus color="lightGrey" mr={2} />
              <Text fontWeight={400} lineHeight={1.5} color="lightGrey">
                {hasEnhancement
                  ? 'Add Enhancements'
                  : 'Enhance Your Background Image'}
              </Text>
            </CButton>
          </motion.div>
        </Box>
      )}

      {isSelectEnhancementsState && (
        <div>
          {isPresetSelectionState ? (
            <SimpleGrid spacing={16} columns={3} mt={20}>
              {selectedCategory?.presets.map((p) => (
                <Preset
                  onSelect={(en) => {
                    aiFlow.aifFlowService.send({
                      type: 'SELECT_PRESET',
                      preset: en,
                    });
                  }}
                  key={p.label}
                  preset={p}
                />
              ))}
            </SimpleGrid>
          ) : (
            <SimpleGrid spacing={16} columns={3} mt={20}>
              {PROMPT_ENHANCEMENTS.map((e) => (
                <Enhancement
                  onSelect={(en) => {
                    aiFlow.aifFlowService.send({
                      type: 'SELECT_CATEGORY',
                      category: {
                        preposition: en.preposition,
                        enhancement: en.value,
                        title: en.label,
                        presets: en.presets,
                      },
                    });
                  }}
                  key={e.label}
                  enhancement={e}
                />
              ))}
            </SimpleGrid>
          )}
        </div>
      )}

      <AnimatePresence>
        {!isGeneratingSuccessState ? (
          <>
            {searchingTerm.length > 0 && (
              <motion.div
                key="enhancements_completed"
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.2 }}
              >
                <Button
                  mt={28}
                  disabled={isGeneratingState}
                  onClick={() =>
                    aiFlow.aifFlowService.send('GENERATE_AD_BACKGROUND')
                  }
                >
                  Generate Ad Background
                </Button>
              </motion.div>
            )}
          </>
        ) : (
          <motion.div
            key="enhancements_idle"
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
          >
            <Flex mt={28} width="100%">
              <CButton
                role="group"
                borderColor="#909FBF66"
                borderWidth={1}
                borderRadius={16}
                height={52}
                width={72}
                mr={10}
                bg="white"
                _hover={{
                  bg: 'white',
                  borderColor: 'purple',
                }}
                onClick={() => aiFlow.aifFlowService.send('RESET_GENERATING')}
              >
                <SvgReset _groupHover={{ color: 'purple' }} color="blue" />
              </CButton>

              <Flex flex={1}>
                <Button
                  onClick={() => aiFlow.aifFlowService.send('SAVE_GENERATING')}
                >
                  Save and Continue
                </Button>
              </Flex>
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
