import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Image,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { IElement } from '../../types';
import Button from '../common/Button';
import { MobileBlocker } from '../common/MobileBlocker';
import { StepDivider } from '../common/StepDivider';
import SvgTrash from '../common/svg/SvgTrash';
import { BackgroundImg } from './BackgroundImg';
import { CreationSteps } from './CreationSteps';
import { EnhancementsSelection } from './EnhancementsSelection';
import { InfoBox } from './InfoBox';
import { Step2Box } from './Step2Box';
import { Step3Box } from './Step3Box';
import {
  selectCurrentStep,
  selectEnhancements,
  selectFullPrompt,
  selectIsGeneratingLoadingState,
  selectIsImgSaveLoadingState,
  selectIsSelectEnhancementsState,
  selectSearchingTerm,
  selectSelectedBackground,
  useAiFlow,
} from '../../xstate/aiFlow';
import { useSelector } from '@xstate/react';

const HomeScreen = () => {
  const aiFlow = useAiFlow();
  const searchingTerm = useSelector(aiFlow.aifFlowService, selectSearchingTerm);
  const currentFlowStep = useSelector(aiFlow.aifFlowService, selectCurrentStep);
  const isSelectEnhancementsState = useSelector(
    aiFlow.aifFlowService,
    selectIsSelectEnhancementsState,
  );
  const fullPrompt = useSelector(aiFlow.aifFlowService, selectFullPrompt);
  const selectedBackground = useSelector(
    aiFlow.aifFlowService,
    selectSelectedBackground,
  );
  const isGeneratingLoadingState = useSelector(
    aiFlow.aifFlowService,
    selectIsGeneratingLoadingState,
  );

  const isImgSaveLoadingState = useSelector(
    aiFlow.aifFlowService,
    selectIsImgSaveLoadingState,
  );
  const enhancements = useSelector(aiFlow.aifFlowService, selectEnhancements);
  const [termIsFocus, setTermIsFocus] = useState(false);
  const [elements, setElements] = useState<IElement[]>([]);
  const isMobile = useIsMobile();
  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);

  const handleAssetClick = (target: HTMLElement) => {
    setElements((prevElements) => [
      ...prevElements,
      { el: target, uid: Math.random() },
    ]);
  };

  useEffect(() => {
    switch (currentFlowStep) {
      case 2:
        step2Ref.current?.scrollIntoView();
        break;
      case 3:
        step3Ref.current?.scrollIntoView();
        break;
    }
  }, [currentFlowStep]);

  if (isMobile) {
    return <MobileBlocker />;
  }

  return (
    <Box pt={78} pb={100}>
      <Grid
        templateColumns={{ base: '1fr', xl: 'minmax(min-content, 423px) 1fr' }}
        columnGap={24}
      >
        <GridItem>
          <CreationSteps />

          {currentFlowStep === 1 && (
            <InfoBox
              currentInfo={searchingTerm.length > 0 && !termIsFocus ? 2 : 1}
            />
          )}

          <motion.div>
            <Box
              mt={21}
              border="1px solid rgba(144, 159, 191, 0.2)"
              borderRadius={24}
              p={24}
              bg="white"
            >
              {currentFlowStep === 1 ? (
                <Box>
                  <InputGroup
                    height={60}
                    // px={16}
                    bg={
                      searchingTerm.length > 0 && !termIsFocus
                        ? '#F6F8FA'
                        : 'white'
                    }
                    _hover={{
                      bg: '#F6F8FA',
                    }}
                    borderRadius={16}
                  >
                    <Input
                      _placeholder={{
                        color: 'lightGrey',
                      }}
                      disabled={isGeneratingLoadingState}
                      value={searchingTerm}
                      onFocus={() => setTermIsFocus(true)}
                      onBlur={() => setTermIsFocus(false)}
                      onChange={(e) => {
                        aiFlow.aifFlowService.send({
                          type: 'SEARCHING_TERM_CHANGE',
                          term: e.target.value,
                        });
                      }}
                      lineHeight="22px"
                      height={60}
                      borderRadius={16}
                      pl={16}
                      pr={52}
                      focusBorderColor="purple"
                      placeholder="Try typing “sandy beach and blue skies”"
                    />
                    <AnimatePresence>
                      {currentFlowStep === 1 &&
                        searchingTerm &&
                        !termIsFocus &&
                        !isGeneratingLoadingState && (
                          <motion.div
                            layout
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                          >
                            <InputRightElement
                              height={60}
                              borderTopRightRadius={16}
                              borderBottomRightRadius={16}
                            >
                              <IconButton
                                height={52}
                                width={52}
                                aria-label="delete"
                                bg="transparent"
                                _hover={{
                                  bg: 'transparent',
                                }}
                                _focus={{
                                  bg: 'transparent',
                                }}
                                onClick={() => {
                                  aiFlow.aifFlowService.send(
                                    'DELETE_SEARCH_TERM',
                                  );
                                }}
                              >
                                <SvgTrash color="darkGrey" />
                              </IconButton>
                            </InputRightElement>
                          </motion.div>
                        )}
                    </AnimatePresence>
                  </InputGroup>

                  <Divider my={24} borderColor="#8084941A" />

                  <EnhancementsSelection />
                </Box>
              ) : (
                <Box>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text
                      mr={8}
                      color="darkGrey"
                      fontWeight={600}
                      _hover={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => aiFlow.aifFlowService.send('TO_STEP_1')}
                    >
                      Background
                    </Text>
                    <Text fontWeight={400} color="lightGrey" fontSize={14}>
                      {enhancements.length} enhancement
                      {enhancements.length > 1 ? 's' : ''}
                    </Text>
                  </Flex>

                  <Box height={1} my={20} width="100%" bg="#8084941A" />

                  <Flex alignItems="center">
                    <Box
                      bg="lighterGrey"
                      mr={12}
                      height={48}
                      width={48}
                      borderRadius={16}
                      overflow="hidden"
                    >
                      {selectedBackground && (
                        <Image
                          height="100%"
                          width="100%"
                          src={selectedBackground}
                        />
                      )}
                    </Box>
                    <Flex flex={1}>
                      <Text fontWeight={400} lineHeight={1.5}>
                        {fullPrompt}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              )}
            </Box>
          </motion.div>

          <StepDivider />

          <Step2Box setElements={setElements} boxRef={step2Ref} />

          <StepDivider />

          <Step3Box handleAssetClick={handleAssetClick} boxRef={step3Ref} />
        </GridItem>

        <GridItem mt={{ base: 24, xl: 0 }}>
          <BackgroundImg
            canvasRef={aiFlow.canvasRef}
            setElements={setElements}
            elements={elements}
            enhanceOn={isSelectEnhancementsState}
          />

          <AnimatePresence>
            {currentFlowStep === 4 && (
              <motion.div
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ y: 10, duration: 0.2, opacity: 0 }}
              >
                <Button
                  onClick={() => aiFlow.aifFlowService.send('SAVE_IMAGE')}
                  isLoading={isImgSaveLoadingState}
                  mt={24}
                >
                  Download
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default HomeScreen;
