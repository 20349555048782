import { Flex } from '@chakra-ui/react';
import React, { ChangeEvent } from 'react';
import SvgUpload from './svg/SvgUpload';

export const InputFilesAsButton = ({
  onChange,
}: {
  onChange: (files: File[]) => void;
}) => {
  const handleUpload = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    const files = Array.from(target.files || []);
    onChange(files);
  };

  return (
    <label
      htmlFor="images"
      style={{
        display: 'block',
        height: 52,
        width: 72,
        borderRadius: 16,
        border: '1px solid #909FBF66',
        backgroundColor: 'white',
        color: 'white',
        marginRight: 10,
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <SvgUpload _groupHover={{ color: 'purple' }} color="blue" />
      </Flex>
      <input
        type="file"
        id="images"
        name="images"
        accept="image/png, image/jpeg, image/svg+xml"
        onChange={handleUpload}
        multiple
        style={{ display: 'none' }}
      />
    </label>
  );
};
