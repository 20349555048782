import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import styles from './button.module.css';

const Button = ({
  text,
  ...rest
}: { text: string } & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  return (
    <button className={styles.root} {...rest}>
      <span>{text}</span>
    </button>
  );
};

export default Button;
