import React from 'react';
import { RESIZE_CORNERS, RESIZE_SIDES } from '../../../constants/enhancements';
import { IResizeDirection } from '../../../types';
import styles from './index.module.css';

const Selection = ({
  onResizeStart,
}: {
  onResizeStart: (e: React.MouseEvent, direction: IResizeDirection) => void;
}) => {
  return (
    <div className={styles.selection} data-role="selection">
      {RESIZE_CORNERS.map((direction) => (
        <div
          key={direction}
          data-direction={direction}
          className={styles.corner}
          onMouseDown={(e) => onResizeStart(e, direction)}
        />
      ))}
      {RESIZE_SIDES.map((direction) => (
        <div
          key={direction}
          data-direction={direction}
          className={styles.side}
          onMouseDown={(e) => onResizeStart(e, direction)}
        />
      ))}
    </div>
  );
};

export default Selection;
