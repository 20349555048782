import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { Container, Flex } from '@chakra-ui/react';

import { useIsMobile } from '../../hooks/useIsMobile';
import Nav from '../common/Nav';

const HomeLayout = () => {
  const isMobile = useIsMobile();

  return (
    <Flex flex={1} overflowX="hidden" minHeight="100vh" width="100%">
      <Flex
        flexDirection="column"
        flex={1}
        width="100%"
        bg="#F5F7FB"
        minHeight="100vh"
      >
        <Container
          maxW={isMobile ? '100%' : '80%'}
          px={isMobile ? 20 : 0}
          pt={24}
        >
          <Nav />
          <Outlet />
        </Container>
      </Flex>
    </Flex>
  );
};

export default HomeLayout;
