import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import NotFoundScreen from './404/NotFoundScreen';
import { AuthProvider, RequireAuth, RequireSignOut } from './auth/AuthProvider';
import HomeScreen from './home/HomeScreen';
import LandingPage from './landing';
import HomeLayout from './layout/HomeLayout';
import Layout from './layout/Layout';

/**
 * User can access `/app` only if logged in.
 * User can access `/` only if logged out (landing page with sign up form).
 * Both `/app` and `/` have loading state while checking if user is logged in.
 * [if enabled] User can access `/home` (landing page) without loader,
 * if user is logged in form will be replaces with a link to `/app` (async).
 */

const Router = () => {
  return (
    <BrowserRouter key="switch">
      <AuthProvider>
        <Routes>
          {/* -- Routes using the Layout -- */}
          <Route element={<Layout />}>
            <Route
              path="/"
              element={
                <RequireSignOut>
                  <LandingPage />
                </RequireSignOut>
              }
              key="LandingScreen"
            />
            <Route
              path="/callback"
              element={
                <RequireSignOut>
                  <LandingPage />
                </RequireSignOut>
              }
              key="LandingCallbackScreen"
            />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <HomeLayout />
                </RequireAuth>
              }
            >
              <Route path="/app" element={<HomeScreen />} key="HomeScreen" />
            </Route>
            <Route element={<HomeLayout />}>
              <Route path="*" element={<NotFoundScreen />} key="Notfound404" />
            </Route>
            {/* <Route path="/home" element={<LandingPage />} key="LandingScreen" /> */}
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;
