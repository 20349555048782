import { ChakraProvider } from '@chakra-ui/react';
import * as React from 'react';
import { theme } from '../constants/theme';

import { rootStore } from '../stores';
import { AIFlowStateProvider } from '../xstate/aiFlow';
import Fonts from './common/Fonts';
import Router from './Router';
import { inspect } from '@xstate/inspect';

if (process.env.NODE_ENV === 'development') {
  inspect({
    // options
    // url: 'https://stately.ai/viz?inspect', // (default)
    iframe: false, // open in new window
  });
}

export const StoreContext = React.createContext<typeof rootStore>(rootStore);

class App extends React.Component {
  render() {
    return (
      <StoreContext.Provider value={rootStore}>
        <ChakraProvider theme={theme}>
          <AIFlowStateProvider>
            <>
              <Fonts />
              <Router />
            </>
          </AIFlowStateProvider>
        </ChakraProvider>
      </StoreContext.Provider>
    );
  }
}

export default App;
