export type Id = string;

export enum IResizeDirection {
  TOP = 'TOP',
  RIGHT = 'RIGHT',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',

  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
}

export interface IAsset {
  uid: number;
  x: number;
  y: number;
  width: number;
  height: number;

  name?: string;
  src?: string;
  text?: string;

  fontSize: number;
  color: string;
  zIndex: number;
}

export interface IBackground {
  url: string;
  // query: string;
}

export interface IElement {
  uid: number;
  el: HTMLElement;
  x?: number;
  y?: number;
}

export interface IDrag {
  el: HTMLElement;
  x: number;
  y: number;
}

export enum IAction {
  MOVE = 'MOVE',
  RESIZE = 'RESIZE',
}
