import { IResizeDirection } from '../types';
import { IEnhancement, IEnhancementSelected, IPreset } from '../utils/api';

export const RESIZE_SIDES = [
  IResizeDirection.TOP,
  IResizeDirection.RIGHT,
  IResizeDirection.BOTTOM,
  IResizeDirection.LEFT,
];

export const RESIZE_CORNERS = [
  IResizeDirection.TOP_LEFT,
  IResizeDirection.TOP_RIGHT,
  IResizeDirection.BOTTOM_RIGHT,
  IResizeDirection.BOTTOM_LEFT,
];

export interface IEnhancementWithPreset {
  enhancement: string;
  preset: IPreset;
  preposition: string;
}

export const PROMPT_ENHANCEMENTS: IEnhancement[] = [
  {
    label: 'Image Style',
    value: 'STYLE',
    preposition: 'in a {value} style',
    selectionTitle: 'Select image style',
    image: '/images/image-style.png',
    presets: [
      {
        label: 'Retro',
        value: 'retro',
        image: '/images/image-style-retro.png',
      },
      {
        label: 'Rendering',
        value: 'render',
        image: '/images/image-style-rendering.png',
      },
      {
        label: 'Hyperrealism',
        value: 'hyperrealism',
        image: '/images/image-style-hyperrealism.png',
      },
      {
        label: 'Drawing',
        value: 'drawing',
        image: '/images/image-style-drawing.png',
      },
      {
        label: 'Painting',
        value: 'painting',
        image: '/images/image-style-painting.png',
      },
      {
        label: 'Water color',
        value: 'watercolor',
        image: '/images/image-style-water-color.png',
      },
    ],
  },
  {
    label: 'Color',
    value: 'COLOR',
    selectionTitle: 'Select color overlay',
    preposition: 'with a {value} overlay',
    image: '/images/image-color.png',
    presets: [
      {
        label: 'Multicolor',
        value: 'multicolor',
        image: '/images/image-color-multicolor.png',
      },
      {
        label: 'Grayscale',
        value: 'grayscale',
        image: '/images/image-color-grayscale.png',
      },
      {
        label: 'Sepia',
        value: 'sepia',
        image: '/images/image-color-sepia.png',
      },
      {
        label: 'Neon',
        value: 'neon',
        image: '/images/image-color-neon.png',
      },
      {
        label: 'Ultraviolet',
        value: 'ultraviolet',
        image: '/images/image-color-ultraviolet.png',
      },
      {
        label: 'Gradient',
        value: 'gradient',
        image: '/images/image-color-gradient.png',
      },
    ],
  },
  {
    label: 'Artist Style',
    value: 'ARTIST_STYLE',
    selectionTitle: 'Select artist style',
    preposition: 'in a {value} artistic style',
    image: '/images/image-artist-style.png',
    presets: [
      {
        label: 'Shepard F.',
        value: 'Shepard F.',
        image: '/images/image-artist-style-shepard-f.png',
      },
      {
        label: 'Julian R.',
        value: 'Julian R.',
        image: '/images/image-artist-style-julian-r.png',
      },
      {
        label: 'Peter M.',
        value: 'Peter M.',
        image: '/images/image-artist-style-peter-m.png',
      },
      {
        label: 'Roy L.',
        value: 'Roy L.',
        image: '/images/image-artist-style-roy-l.png',
      },
      {
        label: 'Piet M.',
        value: 'Piet M.',
        image: '/images/image-artist-style-piet-m.png',
      },
      {
        label: 'Solve S.',
        value: 'Solve S.',
        image: '/images/image-artist-style-solve-s.png',
      },
    ],
  },
  {
    label: 'Lighting',
    value: 'LIGHT',
    preposition: 'with a {value} light',
    selectionTitle: 'Select light preset',
    image: '/images/image-lightning.png',
    presets: [
      {
        label: 'Frontal',
        value: 'frontal',
        image: '/images/image-lightning-frontal.png',
      },
      {
        label: 'Side',
        value: 'side',
        image: '/images/image-lightning-side.png',
      },
      {
        label: 'Overhead',
        value: 'overhead',
        image: '/images/image-lightning-overhead.png',
      },
      {
        label: 'Chiroscuro',
        value: 'chiroscuro',
        image: '/images/image-lightning-chiroscuro.png',
      },
      {
        label: 'Backliting',
        value: 'backliting',
        image: '/images/image-lightning-backliting.png',
      },
      {
        label: 'Bloom',
        value: 'bloom',
        image: '/images/image-lightning-bloom.png',
      },
    ],
  },
  {
    label: 'View',
    value: 'VIEW',
    image: '/images/image-view.png',
    selectionTitle: 'Select View preset',
    preposition: 'seen from the {value}',
    presets: [
      { label: 'Front', value: 'front', image: '/images/image-view-front.png' },
      { label: 'Side', value: 'side', image: '/images/image-view-side.png' },
      { label: 'Top', value: 'top', image: '/images/image-view-top.png' },
      {
        label: 'Isometric',
        value: 'isometric',
        image: '/images/image-view-isometric.png',
      },
      {
        label: 'Perspective',
        value: 'perspective',
        image: '/images/image-view-perspective.png',
      },
      {
        label: 'Distorted',
        value: 'distorted',
        image: '/images/image-view-distorted.png',
      },
    ],
  },
  {
    label: 'Detail Level',
    value: 'RESOLUTION',
    selectionTitle: 'Select detail level',
    preposition: 'in {value} resolution',
    image: '/images/image-detail-level.png',
    presets: [
      {
        label: 'HD',
        value: 'hd',
        image: '/images/image-detail-level-hd.png',
      },
      {
        label: '4K',
        value: '4k',
        image: '/images/image-detail-level-4k.png',
      },
      {
        label: '8K',
        value: '8k',
        image: '/images/image-detail-level-8k.png',
      },
    ],
  },
];
