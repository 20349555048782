import {
  AspectRatio,
  Box,
  Flex,
  SimpleGrid,
  Text,
  Image,
} from '@chakra-ui/react';
import { useSelector } from '@xstate/react';
import { truncate } from 'lodash';
import React, { RefObject } from 'react';
import { IElement } from '../../types';
import {
  selectCurrentStep,
  selectSelectedFiles,
  selectUploadFiles,
  useAiFlow,
} from '../../xstate/aiFlow';
import Button from '../common/Button';
import { InputFiles } from '../common/InputFiles';
import { InputFilesAsButton } from '../common/InputFilesAsButton';
import { StepNumber } from '../common/StepNumber';

interface Props {
  boxRef: RefObject<HTMLDivElement>;
  setElements: React.Dispatch<React.SetStateAction<IElement[]>>;
}

export const Step2Box = ({ boxRef, setElements }: Props) => {
  const aiFlow = useAiFlow();
  const currentStep = useSelector(aiFlow.aifFlowService, selectCurrentStep);
  const uploadFiles = useSelector(aiFlow.aifFlowService, selectUploadFiles);
  const selectedFiles = useSelector(aiFlow.aifFlowService, selectSelectedFiles);

  console.log('selectedFiles', selectedFiles);

  const onSavePress = () => {
    aiFlow.aifFlowService.send('SAVE_UPLOAD');
  };

  const handleAssetClick = (target: HTMLElement) => {
    setElements((prevElements) => [
      ...prevElements,
      { el: target, uid: Math.random() },
    ]);
  };

  return (
    <Box ref={boxRef}>
      {currentStep === 1 && selectedFiles.length === 0 ? (
        <Flex
          border="1px solid rgba(144, 159, 191, 0.1)"
          borderRadius={24}
          p={24}
          bg="white"
          alignItems="center"
        >
          <Box mr={8}>
            <StepNumber value={2} />
          </Box>
          <Text lineHeight="22px" fontWeight={600} color="lightGrey">
            Add Your Product & Logo
          </Text>
        </Flex>
      ) : currentStep === 2 ? (
        <>
          <Box
            border="1px solid rgba(144, 159, 191, 0.1)"
            borderRadius={24}
            p={24}
            bg="white"
          >
            <Text lineHeight="22px" fontWeight={600} color="purple">
              Add Your Product & Logo
            </Text>

            <Text fontWeight={400} lineHeight={1.5} mt={4} color="lightGrey">
              Upload .PNG or .JPEG files for your product and logo and add them
              to your background.
            </Text>
          </Box>
          <Box
            mt={24}
            border="1px solid rgba(144, 159, 191, 0.2)"
            borderRadius={24}
            p={24}
            bg="white"
          >
            <SimpleGrid columns={3} spacing={16}>
              {uploadFiles.map((file) => (
                <AspectRatio key={file.name} ratio={1} width="100%">
                  <Image
                    onClick={(e) => {
                      aiFlow.aifFlowService.send({
                        type: 'SELECT_FILE',
                        files: [file],
                      });
                      handleAssetClick(e.target as HTMLElement);
                    }}
                    cursor="pointer"
                    bg="#909FBF33"
                    src={URL.createObjectURL(file)}
                    data-name={file.name}
                    height="100%"
                    width="100%"
                    borderRadius={20}
                    border="1px solid rgba(144, 159, 191, 0.2)"
                  />
                </AspectRatio>
              ))}
            </SimpleGrid>
            <Box mt={6}>
              <Text lineHeight="22px" fontWeight={600} color="darkGrey">
                Your Uploads
              </Text>

              <Text fontWeight={400} lineHeight="20px" mt={8} color="lightGrey">
                Image files uploaded will be temporarily saved until the end of
                your session.
              </Text>
            </Box>

            {/* <motion.div
              layout
              transition={{ duration: 0.3, ease: 'easeInOut' }}
            > */}
            <Box mt={24}>
              {uploadFiles.length === 0 ? (
                <InputFiles
                  onChange={(files) =>
                    aiFlow.aifFlowService.send({
                      type: 'UPLOAD_FILES',
                      files,
                    })
                  }
                />
              ) : (
                <Flex>
                  {/* <CButton
                    role="group"
                    borderColor="#909FBF66"
                    borderWidth={1}
                    borderRadius={16}
                    height={52}
                    width={72}
                    mr={10}
                    bg="white"
                    _hover={{
                      bg: 'white',
                      borderColor: 'purple',
                    }}
                  >
                    <SvgUpload _groupHover={{ color: 'purple' }} color="blue" />
                  </CButton> */}

                  <InputFilesAsButton
                    onChange={(files) =>
                      aiFlow.aifFlowService.send({
                        type: 'UPLOAD_FILES',
                        files,
                      })
                    }
                  />

                  <Flex flex={1}>
                    <Button onClick={onSavePress}>Save and continue</Button>
                  </Flex>
                </Flex>
              )}
            </Box>
            {/* </motion.div> */}
          </Box>
        </>
      ) : (
        <Box
          bg="white"
          border="1px solid rgba(144, 159, 191, 0.1)"
          borderRadius={24}
          p={24}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Text
              mr={8}
              color="darkGrey"
              fontWeight={600}
              _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => aiFlow.aifFlowService.send('TO_STEP_2')}
            >
              Product Assets
            </Text>
            <Text fontWeight={400} color="lightGrey" fontSize={14}>
              {selectedFiles.length} image{selectedFiles.length > 1 ? 's' : ''}
            </Text>
          </Flex>

          <Box height={1} my={20} width="100%" bg="#8084941A" />

          <Flex alignItems="center">
            <SimpleGrid columns={3} spacing={12} mr={20}>
              {selectedFiles.map((file) => (
                <Box
                  key={file.name}
                  bg="lighterGrey"
                  height={48}
                  width={48}
                  borderRadius={16}
                  overflow="hidden"
                >
                  <img
                    src={URL.createObjectURL(file)}
                    style={{
                      objectFit: 'cover',
                      // height: '100%',
                    }}
                  />
                </Box>
              ))}
            </SimpleGrid>

            <Flex flex={1}>
              <Box>
                {selectedFiles.map((file) => (
                  <Text
                    key={file.name}
                    fontWeight={400}
                    lineHeight={1.5}
                    color="darkGrey"
                  >
                    {truncate(file.name, { length: 20 })}
                  </Text>
                ))}
              </Box>
            </Flex>
          </Flex>
        </Box>
      )}
    </Box>
  );
};
