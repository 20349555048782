import querystring from 'querystring';

import { IEnhancementWithPreset } from '../constants/enhancements';

export const REACT_APP_BASE_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:8080';

const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

export function login(didToken: string) {
  return fetch(`${REACT_APP_BASE_URL}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + didToken,
    },
    credentials: "include",
  });
}

export function getMe() {
  return get('/me');
}

function get(url: string, params = {}) {
  const queryString = '?' + querystring.stringify(params);
  return _fetch(REACT_APP_BASE_URL + url + queryString, {
    method: 'GET',
    headers: getHeaders(),
  });
}

function post(url: string, data = {}) {
  return _fetch(REACT_APP_BASE_URL + url, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
}

async function _fetch(url: string, options: any) {
  try {
    const res = await fetch(url, options);
    if (res.status !== 200) {
      const resJson = await res.json();
      throw resJson.error;
    }
    return res.json();
  } catch (errorObject) {
    throw new Error(errorObject as string);
  }
}

function getHeaders() {
  // const { token } = user;

  // if (token) {
  //   return {
  //     ...BASE_HEADERS,
  //     authorization: token,
  //   };
  // }
  return BASE_HEADERS;
}

export interface IPreset {
  label: string;
  value: string;
  image: string;
}

export interface IEnhancement {
  label: string;
  value: string;
  preposition: string;
  presets: IPreset[];
  selectionTitle: string;
  image: string;
}

export interface IEnhancementSelected extends IEnhancement {
  selected: IPreset | null;
}

export const getTextToImage = async (
  prompt: string,
): Promise<{ images: Array<{ url: string; query: string }> }> => {
  const response = await post('/create', { query: prompt, openai: false });
  console.log('getTxt2Img', response?.data);
  return { images: response?.data };
};

export const getPresetsString = (
  prompt: string,
  enhancements: IEnhancementWithPreset[],
) => {
  return (
    prompt +
    ' ' +
    enhancements
      .map((item) => {
        const { preposition, preset } = item;
        return preposition.replace(/{value}/, preset.value);
      })
      .join(' ')
  );
};

const stringToHTML = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.firstElementChild;
};

const fileToText = (imageFile: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result as string);
    };

    reader.readAsText(imageFile);
  });
};

const svgToDataURL = (svg: string) => {
  // trim
  svg = svg.trim();
  // remove xml, doctype, generator...
  svg = svg.slice(svg.indexOf('<svg'));
  // soft validate
  if (!svg.startsWith('<svg') || !svg.endsWith('svg>')) return null;
  // add namespace if necessary
  if (!svg.includes('http://www.w3.org/2000/svg')) {
    svg = svg.replace(/<svg/g, `<svg xmlns='http://www.w3.org/2000/svg'`);
  }
  // remove comments
  svg = svg.replace(/<!--.{1,}-->/g, '');
  // remove unnecessary attributes
  svg = svg.replace(/version=["'](.{0,}?)["'](?=[\s>])/g, '');
  // svg = svg.replace(/id=[\"\'](.{0,}?)[\"\'](?=[\s>])/g, '');
  // svg = svg.replace(/class=[\"\'](.{0,}?)[\"\'](?=[\s>])/g, '');
  // replace nested quotes
  svg = svg.replace(/"'(.{1,})'"/g, "'$1'");
  // replace double quotes
  svg = svg.replace(/"/g, "'");
  // remove empty spaces between tags
  svg = svg.replace(/>\s{1,}</g, '><');
  // remove duplicate spaces
  svg = svg.replace(/\s{2,}/g, ' ');
  // trim again
  svg = svg.trim();
  // soft validate again
  if (!svg.startsWith('<svg') || !svg.endsWith('svg>')) return null;
  // replace ampersand
  svg = svg.replace(/&/g, '&amp;');
  // encode only unsafe symbols
  svg = svg.replace(/[%#<>?[\\\]^`{|}]/g, encodeURIComponent);

  return `data:image/svg+xml,${svg}`;
};

const svgFileToDataURL = async (
  file: File,
  { width, height }: { width: number; height: number },
) => {
  if (file.type === 'image/svg+xml') {
    const svgText = await fileToText(file);
    const svgEl = stringToHTML(svgText);

    if (svgEl) {
      svgEl.setAttribute('width', width.toString());
      svgEl.setAttribute('height', height.toString());

      return svgToDataURL(svgEl.outerHTML);
    }
  }

  return URL.createObjectURL(file);
};

export const toDataURL = async (
  src: string,
  outputFormat = 'image/png',
): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
      const ctx = canvas.getContext('2d')!;

      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0, img.naturalHeight, img.naturalWidth);

      const dataURL = canvas.toDataURL(outputFormat);
      resolve(dataURL);
    };

    img.crossOrigin = 'Anonymous';
    img.src = src;
  });
};

const remoteUrlToDataURL = async (
  src: string,
  outputFormat = 'image/png',
): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
      const ctx = canvas.getContext('2d')!;

      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0, img.naturalHeight, img.naturalWidth);

      const dataURL = canvas.toDataURL(outputFormat);
      resolve(dataURL);
    };

    img.crossOrigin = 'Anonymous';
    img.src = src;
  });
};

export const processImages = async (document: HTMLElement, files: File[]) => {
  const el = document.querySelector('[data-role="canvas"')!;
  const selection = el.querySelector("[data-role='selection']");
  const images = el.querySelectorAll('img');

  if (selection) {
    selection.remove();
  }

  return await Promise.allSettled(
    Array.from(images).map(
      (img) =>
        new Promise(async (resolve) => {
          const file = files.find(
            (file) => file.name === img.getAttribute('data-name'),
          );

          // local url [svg]
          if (file && file.type === 'image/svg+xml') {
            const newUrl = await svgFileToDataURL(file, {
              width: img.width,
              height: img.height,
            });

            if (newUrl) {
              img.src = newUrl;
            }

            return resolve(null);
          }

          // local url
          if (/^blob:/.test(img.src)) {
            return resolve(null);
          }

          // remote url
          // const dataUrl = await remoteUrlToDataURL(img.src);
          // img.src = dataUrl;
          resolve(null);
        }),
    ),
  );
};
