import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useNavigationStore } from '../../stores/navigation';
import { useUiStore } from '../../stores/ui';

const Layout = observer(() => {
  const uiStore = useUiStore();
  const navigationStore = useNavigationStore();
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    navigationStore.setNavigate(navigate);
  }, [navigate]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  React.useEffect(() => {
    uiStore.updateWindowSizes({
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    });
    window.addEventListener('resize', () => {
      uiStore.updateWindowSizes({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
      });
    });
  }, []);

  return <Outlet />;
});

export default Layout;
