import React from 'react';
import styles from './grid.module.css';
import './animations.css';

const Grid = () => {
  const items = Array.from(Array(12 * 4));
  return (
    <div className={styles.root} data-animation="appear">
      <div className={styles.grid} data-animation="scroll">
        {items.map((_, i) => {
          return (
            <div
              key={i}
              className={styles.card}
              style={{
                backgroundImage: `url(/images/home-gallery/png/${i + 1}.png)`,
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Grid;
