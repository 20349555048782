import React from 'react';
import Grid from './Grid';

import SignUpForm from './SignUpForm';
import styles from './index.module.css';
import './animations.css';

const LandingPage = () => {
  const config = {
    title: 'Digital ad creation, reimagined.',
    subtitle:
      'Generate product-centric digital ads in minutes with no prior design expertise required!',
  };

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <div className={styles.logo} data-animation="slideIn">
          <img
            className={styles.logoImage}
            src="/images/logo-app-light.png"
            alt="logo"
          />
          <span className={styles.logoText}>Alvy.ai</span>
        </div>
      </header>
      <div className={styles.body}>
        <div className={styles.grid}>
          <Grid />
        </div>
        <main className={styles.content} data-animation="slideIn">
          <div className={styles.heading}>
            <h1 className={styles.title}>{config.title}</h1>
            <h2 className={styles.subtitle}>{config.subtitle}</h2>
          </div>
          <div>
            <SignUpForm />
          </div>
        </main>
      </div>
    </div>
  );
};

export default LandingPage;
