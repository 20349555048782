import { Box, Flex, Text } from '@chakra-ui/react';
import { useSelector } from '@xstate/react';
import React, { ReactNode, RefObject } from 'react';
import {
  selectAdColor,
  selectAdCopies,
  selectAdText,
  selectCurrentStep,
  useAiFlow,
} from '../../xstate/aiFlow';
import Button from '../common/Button';
import TextForm from '../common/editor/TextForm';
import { StepNumber } from '../common/StepNumber';

interface Props {
  boxRef: RefObject<HTMLDivElement>;
  handleAssetClick: (target: HTMLElement) => void;
}

export const Step3Box = ({ boxRef, handleAssetClick }: Props) => {
  const aiFlow = useAiFlow();
  const adColor = useSelector(aiFlow.aifFlowService, selectAdColor);
  const adText = useSelector(aiFlow.aifFlowService, selectAdText);
  const currentStep = useSelector(aiFlow.aifFlowService, selectCurrentStep);
  const adCopies = useSelector(aiFlow.aifFlowService, selectAdCopies);

  const onSave = () => {
    aiFlow.aifFlowService.send('AD_SAVE');
  };

  let component: ReactNode = (
    <Flex
      border="1px solid rgba(144, 159, 191, 0.1)"
      borderRadius={24}
      p={24}
      bg="white"
      alignItems="center"
    >
      <Box mr={8}>
        <StepNumber value={3} />
      </Box>
      <Flex alignItems="center" flex={1} justifyContent="space-between">
        <Text lineHeight="22px" fontWeight={600} color="lightGrey">
          Add Copy
        </Text>
        <Text color="lightGrey" fontWeight={400} lineHeight="22px">
          Optional
        </Text>
      </Flex>
    </Flex>
  );

  if (currentStep === 3) {
    component = (
      <>
        <Box
          border="1px solid rgba(144, 159, 191, 0.1)"
          borderRadius={24}
          p={24}
          bg="white"
        >
          <Text lineHeight="22px" fontWeight={600} color="purple">
            Add Copy
          </Text>

          <Text fontWeight={400} lineHeight={1.5} mt={4} color="lightGrey">
            Type a quick sentence or phrase in the bar
          </Text>
        </Box>

        <Box
          mt={24}
          border="1px solid rgba(144, 159, 191, 0.2)"
          borderRadius={24}
          p={24}
          bg="white"
        >
          <TextForm
            text={adText}
            color={adColor}
            onTextChange={(value) =>
              aiFlow.aifFlowService.send({
                type: 'AD_COPY_CHANGE',
                text: value,
              })
            }
            onColorChange={(value) =>
              aiFlow.aifFlowService.send({
                type: 'AD_COLOR_CHANGE',
                color: value,
              })
            }
            asSelectedText={adCopies.length > 0}
            onTextClick={(el) => {
              handleAssetClick(el);
              setTimeout(() => {
                aiFlow.aifFlowService.send('AD_SUBMIT');
              }, 1);
            }}
          />

          {adCopies.length > 0 && (
            <Button onClick={onSave}>Save and continue</Button>
          )}

          {/* <Input
            _placeholder={{
              color: 'lightGrey',
            }}
            lineHeight="22px"
            height={60}
            borderRadius={16}
            px={16}
            focusBorderColor="purple"
            placeholder="Type text"
          />

          <Box height={1} my={20} width="100%" bg="#8084941A" />

          <CButton bg="transparent">
            <Flex alignItems="center">
              <SvgArrowRight color="lightGrey" mr={8} />
              <Text fontWeight={400} lineHeight={1.5} color="lightGrey">
                Add text to canvas
              </Text>
            </Flex>
          </CButton> */}
        </Box>
      </>
    );
  }

  if (adCopies.length > 0) {
    component = (
      <>
        <Box
          border="1px solid rgba(144, 159, 191, 0.1)"
          borderRadius={24}
          p={24}
          bg="white"
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Text lineHeight="24px" fontWeight={600}>
              Ad Copy
            </Text>

            <Box
              border="1px solid rgba(144, 159, 191, 0.2)"
              overflow="hidden"
              p={0}
              height={16}
              width={16}
              borderRadius={4}
            >
              <Box bg={adCopies[0].color} height="100%" width="100%" />
            </Box>
          </Flex>

          <Box height={1} my={20} width="100%" bg="#8084941A" />

          <Text fontWeight={400} lineHeight={1.5} mt={4} color="lightGrey">
            {adCopies[0].text}
          </Text>
        </Box>
      </>
    );
  }

  return <Box ref={boxRef}>{component}</Box>;
};
