import { Flex, Text, SimpleGrid } from '@chakra-ui/react';
import { useSelector } from '@xstate/react';
import { motion } from 'framer-motion';
import React from 'react';
import { selectCurrentStep, useAiFlow } from '../../xstate/aiFlow';
import { StepNumber } from '../common/StepNumber';
import SvgCheck from '../common/svg/SvgCheck';

const TOTAL_STEPS = 3;

export const CreationSteps = () => {
  const aiFlow = useAiFlow();
  const currentStep = useSelector(aiFlow.aifFlowService, selectCurrentStep);
  return (
    <Flex alignItems="center">
      <Text
        mr={38}
        color="darkGrey"
        fontSize={18}
        fontWeight={600}
        lineHeight={1.5}
      >
        Create Your Ad in {TOTAL_STEPS} Easy Steps
      </Text>

      <SimpleGrid columns={TOTAL_STEPS} spacingX={24} alignItems="center">
        {Array.from({ length: TOTAL_STEPS }).map((_, i) => {
          if (currentStep > i + 1) {
            return (
              <motion.div
                key={i + 1}
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ y: 10, duration: 0.2 }}
              >
                <SvgCheck color="#D4C4FC" />
              </motion.div>
            );
          }

          return (
            <motion.div
              key={i + 1}
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ y: 10, duration: 0.2 }}
            >
              <StepNumber value={i + 1} isActive={currentStep === i + 1} />
            </motion.div>
          );
        })}
      </SimpleGrid>
    </Flex>
  );
};
